<template>
	<div v-if="isSideMenu && !apartmentOnly" class="model-app-side-menu-wrapper fp-side-menu-wrapper"

	>
		<side-menu></side-menu> 
	</div>
	<!-- <div v-if="isSideMenu && !apartmentOnly" class="model-app-side-menu-wrapper fp-side-menu-wrapper">
			<side-menu></side-menu>
	</div> -->
	<div class="model-app fp-app-wrapper"
		:class="{ 'is-mobile': isMobile, 'is-tablet': isTablet, 'is-desktop': isDesktop,'lowResLaptop': lowResLaptop, 'ctrl-360': ctrl360 }"

		>

		<contact-modal></contact-modal>
		<mobile-header></mobile-header>
		<projects-pane v-if="!apartmentOnly"></projects-pane>
		<model-frame v-if="showApartments3DModel" ref="modelFrameRef" width="100%" height="100%"></model-frame>
		<app-bg v-else></app-bg>
		<router-view></router-view>
		<menu-bar v-if="hasBottomBar && !apartmentOnly"></menu-bar>
		<template v-else>
			<div id="menuBarTop"></div> 
		</template>
		<floating-contact-toggle v-if="showFloatingContactButton && !isSideMenu"></floating-contact-toggle>
		<share-dialog></share-dialog>
		<RegistrationProcess v-if="apartmentRegistrationModal" />
		<div v-if="imageCanvas && imageCanvas.src" class="canvas-modal">
			<div class="canvas-modal-body">
				<close-btn @click="$store.dispatch('closeImageEditor')"></close-btn>
				<image-canvas :image-src="imageCanvas.src"></image-canvas>
			</div>
		</div>
		<accessibility-modal></accessibility-modal>
		<div v-if="!isSideMenu" class="credits">powered by <a href="https://studioy.io" target="_blank">studioY.io</a></div>
	</div>
	<!-- <lottie-loader></lottie-loader> -->
	<g-dialog-root></g-dialog-root>
	<div class="loader" v-if="isLoadingWithModel">
		<AnimatedCircles :radius="100" :circleSize="240" :color="colors.primary"/>
	</div>
  <div class="credits">powered by <a href="https://studioy.io" target="_blank">studioY.io</a></div>
</template>

<script>
import MenuBar from "@/components/MenuBar"
import ContactModal from "@/components/ContactModal"
import ProjectsPane from "@/components/ProjectsPane"
import { mapGetters } from "vuex"
import MobileHeader from "@/components/MobileHeader"
// import StagesPane from "@/components/StagesPane"
import FloatingContactToggle from "@/components/FloatingContactToggle"
import ShareDialog from "@/components/ShareDialog"
import CloseBtn from "@/components/buttons/CloseBtn"
import ImageCanvas from "@/components/ImageCanvas"
import AccessibilityModal from "@/components/AccessibilityModal"
import SideMenu from "@/components/SideMenu"
import ModelFrame from "@/components/ModelFrame.vue"

import { GDialogRoot } from "gitart-vue-dialog"
// import LottieLoader from "@/components/LottieLoader.vue"
import AnimatedCircles from "@/components/AnimatedCircles.vue"
import AppBg from "@/components/AppBg.vue";
import RegistrationProcess from "@/components/RegistrationProcess.vue";
import {getValueFromObject} from "@/utils";

export default {
	name: 'ModelApp',
	components: {
		AppBg,
		// LottieLoader,
		GDialogRoot,
		ModelFrame,
		SideMenu,
		AccessibilityModal,
		ImageCanvas,
		CloseBtn,
		// ThreeScene,
		FloatingContactToggle,
		RegistrationProcess,
		MobileHeader,
		ProjectsPane,
		ContactModal,
		MenuBar,
		ShareDialog,
		AnimatedCircles
	},
	data() {
		return {
			app3dReady: false,
			intervalId: null,
      localChangeInterval: null
		}
	},
	watch: {
		isMobile() {
			this.setAppRootWrapperDeviceClasses();
		},
		isTablet() {
			this.setAppRootWrapperDeviceClasses();
		},
		isDesktop() {
			this.setAppRootWrapperDeviceClasses();
		},
		locale() {
      const projectId = this.$store.getters.currentProjectId
      let checksCount = 0;
      if(this.localChangeInterval){
        clearInterval(this.localChangeInterval)
      }
      this.localChangeInterval = setInterval(() => {
        checksCount++;
        const products = this.$store.getters.products;
        const hasProducts = products && ((Array.isArray(products) && products.length > 0) || (typeof(products) === 'object' && Object.keys(products).length !== 0))
        if (this.$store.getters.currentProjectId !== projectId && hasProducts) {
          this.sendData()
          clearInterval(this.localChangeInterval)
        }
        if(checksCount > 50){
          clearInterval(this.localChangeInterval)
        }
      }, 500)
    },
	},
	computed: {
		contentHeight() {
			if (this.isMobile && !this.apartmentOnly) {
				return `calc(100svh - (${this.bottomBarHeight}px + ${this.mobileHeaderHeight}px + ${this.mobileButtonsHeight}px))`
			} else if (this.isSideMenu) {
				return '100svh';
			}
			return `calc(100svh - ${this.bottomBarHeight}px)`
		},
		inAdminPath() {
			return this.$route.path.includes('fp-admin')
		},
    isLoadingWithModel(){
      // if this route is apartments take into account modelsReady
      if(this.showApartments3DModel && this.$route.name === 'apartments'){
        return this.globalLoading || !this.modelsReady;
      }
      return this.globalLoading
    },
    modelsReady: {
      get() {
        return this.$store.getters.modelsReady
      },
      set(value) {
        this.$store.commit('modelsReady', value)
      }
    },
    animationEnded: {
      get() {
        return this.$store.getters.animationEnded
      },
      set(animationEnded) {
        this.$store.commit('animationEnded', animationEnded)

      }
    },
		...mapGetters([
			'isMobile',
			'isTablet',
			'isDesktop',
			'lowResLaptop',
			'locale',
			'ctrl360',
			'stagesPaneShow',
			'mobileMenuOpen',
			'projectsPaneOpen',
			'settings',
			'imageCanvas',
			'apartmentOnly',
			'isSideMenu',
			'hasBottomBar',
			'mobileHeaderHeight',
			'mobileButtonsHeight',
			'bottomBarHeight',
			'mobileContactFormButtonLocation',
			'hasWhatsappButton',
			'showFloatingContactButton',
			'apartmentRegistrationModal',
			'showHome3DModel',
			'globalLoading',
			'currentProjectId',
			'products',
			'apartmentDataView',
			'colors',
			'currentProject',
			'apartmentBlockFieldsOrder',
			'showApartments3DModel',
		])
	},
	methods: {
		mobileMediaQuery(e) {
			this.$store.dispatch('updateMediaQuery', { mobile: e.matches })
		},
		tabletMediaQuery(e) {
			this.$store.dispatch('updateMediaQuery', { tablet: e.matches })
		},
		lowResLaptopMediaQuery(e) {
			this.$store.dispatch('updateMediaQuery', { lowResLaptop: e.matches })
		},
		closeMobileMenu() {
			if (this.isMobile && this.mobileMenuOpen) {
				this.$store.commit('mobileMenuOpen', false)
			}
			if (this.projectsPaneOpen) {
				this.$store.commit('projectsPaneOpen', false)
			}
		},
		setAppRootWrapperDeviceClasses() {
			const rootElement = this.$root.$el.parentElement;
			if (this.isMobile && !rootElement.classList.contains('is-mobile')) {
				rootElement.classList.add('is-mobile')
			} else if (!this.isMobile && rootElement.classList.contains('is-mobile')) {
				rootElement.classList.remove('is-mobile')
			}
			if (this.isTablet && !rootElement.classList.contains('is-tablet')) {
				rootElement.classList.add('is-tablet')
			} else if (!this.isTablet && rootElement.classList.contains('is-tablet')) {
				rootElement.classList.remove('is-tablet')
			}
			if (this.lowResLaptop && !rootElement.classList.contains('lowResLaptop')) {
				rootElement.classList.add('lowResLaptop')
			} else if (!this.lowResLaptop && rootElement.classList.contains('lowResLaptop')) {
				rootElement.classList.remove('lowResLaptop')
			}
			if (this.isDesktop && !rootElement.classList.contains('is-desktop')) {
				rootElement.classList.add('is-desktop')
			} else if (!this.isDesktop && rootElement.classList.contains('is-desktop')) {
				rootElement.classList.remove('is-desktop')
			}
			if (window.innerWidth > window.innerHeight) {
				if (!rootElement.classList.contains('landscape')) {
					rootElement.classList.add('landscape')
				}
				if (rootElement.classList.contains('portrait')) {
					rootElement.classList.remove('portrait')
				}
			} else {
				if (!rootElement.classList.contains('portrait')) {
					rootElement.classList.add('portrait')
				}
				if (rootElement.classList.contains('landscape')) {
					rootElement.classList.remove('landscape')
				}
			}

		},
    checkDataAndShowErrors(){
      // check if some products don't have type_building
      if(Array.isArray(this.products) && this.products.length > 0){
        const products = this.products;
        const productsWithoutTypeBuilding = products.filter(product => !product.type_building)
        if(productsWithoutTypeBuilding.length > 0){
          console.error(this.$t("חלק או כל הדירות לא מקושרות לטיפוס ו/או בניין"), {
            productsWithoutTypeBuilding
          })
        }
      }else if(typeof(this.products) === 'object' && Object.keys(this.products).length > 0){
        const products = this.products;
        const productsWithoutTypeBuilding = Object.values(products).filter(product => !product.type_building)
        if(productsWithoutTypeBuilding.length > 0){
          console.error(this.$t("חלק או כל הדירות לא מקושרות לטיפוס ו/או בניין"), {
            productsWithoutTypeBuilding
          })
        }
      }
    },
		sendData() {
			if(this.currentProject){
        this.checkDataAndShowErrors();
        console.log("currentProject", this.currentProject)

        const productsTitles = [];
        let products = [];
        // set products title from name_in_model
        if(Array.isArray(this.products)){
          products = this.products;
        } else {
          products = Object.values(this.products);
        }

        products = products.map((product) => {
          if(product.name_in_model){
            product.title = product.name_in_model
          } else if (typeof product.title === 'object'){
            product.title = product.title[Object.keys(product.title)[0]]
          }
          productsTitles.push(product.title)
          return product
        })

        const projectData = {
          action: 'newProjectLoaded',
          projectId: this.currentProjectId,
          products: products,
          buildings: this.currentProject.buildings,
          statuses: this.apartmentDataView && this.apartmentDataView.apartment_statuses ? this.apartmentDataView.apartment_statuses : null,
          apartmentBlockFieldsOrder: this.apartmentBlockFieldsOrder,
          colors: this.colors,
          projectDefaultView: this.currentProject.default_view,
          hdrUrl: this.currentProject?.project_model_hdr?.url,
          // skyImage: this.currentProject?.project_model_sky_image?.url,
          params: { force: true, dc: true, fp_lang: this.locale },
          apartment_enter_button_in_model_text: getValueFromObject(this.$store.getters.settings, 'apartments_data_view.apartment_enter_button_text'),
        }

        console.log({projectData})

        this.broadcastAction(projectData)
			}else{
        console.error("No current project")
      }
		},
		broadcastAction(data) {
			// console.log("broadcastAction", data)
			if (this.$frameBus) {
				this.$frameBus.emit('message', data);
			}
		},
		listenFramebus() {
      console.log('listenFramebus in ModelApp')
			this.$frameBus.on('message', this.onFramebusMessage)
		},
		onFramebusMessage(data) {
			console.log("onFramebusMessage", data)
			const { action } = data
			if (action) {
				switch (action) {
					case 'getData':
						this.sendData()
						break
					case '3dAppReady':
						this.app3dReady = true
            if(this.intervalId){
              clearInterval(this.intervalId)
            }
						this.intervalId = setInterval(() => {
							if (this.products && Object.keys(this.products).length !== 0) {
								this.sendData()
								clearInterval(this.intervalId);
							}
						}, 500);
						break
					case 'modelReady':
						this.modelsReady = true
						break
          case 'reload-model-iframe':
            if(this.$refs.modelFrameRef){
              this.$refs.modelFrameRef.refreshIframe()
            }
            break
          case 'animationEnd':
            this.animationEnded = true
            break
					default:
						break
				}
			}
		},
	},
	mounted() {
		this.listenFramebus();


		const urlParts = window.location.href.split("#");
		if (urlParts[1] && urlParts[1] !== "/") {
			this.$store.commit('mainInit')
		}

		if (this.settings && this.settings.primary_color) {
			this.$store.dispatch('setPrimaryColorAndShades', this.settings.primary_color)
		}

		this.$store.dispatch('preloadAll')
		/*this.$store.dispatch('loadProjects').then(() => {
						this.$store.dispatch('loadSettings')
		})*/

		const mobileMediaQuery = window.matchMedia('(max-width: 767px)')
		mobileMediaQuery.addEventListener('change', this.mobileMediaQuery)
		this.mobileMediaQuery(mobileMediaQuery)

		const tabletMediaQuery = window.matchMedia('(max-width: 992px) and (min-width: 768px)')
		tabletMediaQuery.addEventListener('change', this.tabletMediaQuery)
		this.tabletMediaQuery(tabletMediaQuery)

		const lowResLaptopMediaQuery = window.matchMedia('(min-width: 767px) and (max-width: 1366px) and (max-height: 920px) and (min-height: 600px)')
		lowResLaptopMediaQuery.addEventListener('change', this.lowResLaptopMediaQuery)
		this.lowResLaptopMediaQuery(lowResLaptopMediaQuery)
		this.setAppRootWrapperDeviceClasses()
	},
	async beforeCreate() {
		try {
			await this.$store.dispatch('validateToken')
		} catch (e) {
			console.warn(e)
		}
	}
}
</script>
<style lang="scss" scoped>

.credits {
  bottom: 110px;
  left: 0;
  z-index: 10;
  background-color: #000;
  text-shadow: 3px 3px black;
  line-height: 1;
  font-size: 0.8rem;
  position: absolute;
  border-radius: 10px;
  padding: 5px 10px;
  transform: rotate(90deg) scale(1.1) translateX(-19vh);
  transform-origin: 0 100%;
  -webkit-margin-start: 5px;
  margin-inline-start: 5px;
  white-space: nowrap;

  a {
    color: var(--primary-lighterer);
  }
}

.is-mobile {
  .credits {
    transform: rotate(90deg) scale(0.7) translateX(-19vh);
  }
}

</style>
